.toast-portal {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 9999;
}

.toast {
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  margin-block: 1rem;

  opacity: 1;
  transition: opacity 1.5s ease;
}

.toast.fade-out {
  opacity: 0;
}

.toast p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.toast button {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}
